import Swiper from "swiper";
import "../scss/app.scss";
import { A11y, EffectCards, Navigation, Pagination } from "swiper/modules";

import SelectInput from "./select-input/select-input";
import { selectOne } from "./select-input/modules/select-one";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Switches from "./switches/switches";
import { free } from "./switches/modules/free";

new Switches("[data-accordion]", free, "aria-expanded");

window.addEventListener("load", () => {
  const preloader = document.getElementById("preloader");
  
  setTimeout(() => {
    preloader.style.opacity = 0;
    preloader.style.visibility = "hidden";
    
  }, 500);

  setTimeout(() => {
    preloader.style.display = "none"; // Приховуємо прелоадер
  }, 1500);
});

const nb = document.querySelector("[data-nav-btn]");
const nbg = document.querySelector("[data-nav-background]");
const nl = document.querySelector("[data-nav-list]");
const nls = document.querySelectorAll("[data-nav-link]");
const l = document.querySelector("[data-logo]");

nb.addEventListener("click", () => {
  
  nb.classList.toggle("active");
  nbg.classList.toggle("active");
  nl.classList.toggle("active");
  l.classList.toggle("active");
  
  if (nb.classList.contains("active")) {
    nb.ariaExpanded = true
  } else {
    nb.ariaExpanded = false
  }
});

nls.forEach(nlk => nlk.addEventListener("click", () => {
  nb.classList.toggle("active");
  nbg.classList.toggle("active");
  nl.classList.toggle("active");
  l.classList.toggle("active");
}));

document.querySelectorAll("[data-select-course]").forEach(s => {
  s.addEventListener("click", () => {
    document.getElementById(s.dataset.selectCourse).checked = true;
  });
});

new SelectInput("[data-category-select]", selectOne);

new Swiper("[data-feedbacks-swiper]", {
  modules: [Pagination, Navigation, A11y],
  speed: 600,
  slidesPerView: "auto",
  spaceBetween: 16,
    a11y: {
    enabled: true, // Увімкнення доступності
    prevSlideMessage: 'Попередній слайд',
    nextSlideMessage: 'Наступний слайд',
    firstSlideMessage: 'Це перший слайд',
    lastSlideMessage: 'Це останній слайд',
    paginationBulletMessage: 'Перейти до слайда {{index}}',
  },
  pagination: {
    el: ".swiper-feedbacks-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-feedbacks-button-next",
    prevEl: ".swiper-feedbacks-button-prev",
  },
  breakpoints: {
    600: {
      slidesPerView: 2
    },
    900: {
      slidesPerView: 3
    }
  }
});

new Swiper("[data-works-swiper]", {
  modules: [Pagination, Navigation],
  speed: 600,
  slidesPerView: "auto",
  spaceBetween: 16,
  loop: true,
    a11y: {
    enabled: true, // Увімкнення доступності
    prevSlideMessage: 'Попередній слайд',
    nextSlideMessage: 'Наступний слайд',
    firstSlideMessage: 'Це перший слайд',
    lastSlideMessage: 'Це останній слайд',
    paginationBulletMessage: 'Перейти до слайда {{index}}',
  },
  pagination: {
    el: ".swiper-works-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-works-button-next",
    prevEl: ".swiper-works-button-prev",
  },
  breakpoints: {
    600: {
      slidesPerView: 2
    },
    900: {
      slidesPerView: 3
    }
  }
});

new Swiper("[data-about-swiper]", {
  modules: [ EffectCards, Navigation ],
  effect: "cards",
  grabCursor: true,
  speed: 700,
    a11y: {
    enabled: true, // Увімкнення доступності
    prevSlideMessage: 'Попередній слайд',
    nextSlideMessage: 'Наступний слайд',
    firstSlideMessage: 'Це перший слайд',
    lastSlideMessage: 'Це останній слайд',
    paginationBulletMessage: 'Перейти до слайда {{index}}',
  },

  navigation: {
    nextEl: ".swiper-about-button-next",
    prevEl: ".swiper-about-button-prev",
  },
});

new Swiper("[data-experience-swiper]", {
  modules: [Pagination, Navigation],
  speed: 700,
  slidesPerView: "auto",
  spaceBetween: 16,
    a11y: {
    enabled: true, // Увімкнення доступності
    prevSlideMessage: 'Попередній слайд',
    nextSlideMessage: 'Наступний слайд',
    firstSlideMessage: 'Це перший слайд',
    lastSlideMessage: 'Це останній слайд',
    paginationBulletMessage: 'Перейти до слайда {{index}}',
  },
  pagination: {
    el: ".swiper-experience-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-experience-button-next",
    prevEl: ".swiper-experience-button-prev",
  },
  breakpoints: {
    600: {
      slidesPerView: 2
    },
    900: {
      slidesPerView: "auto"
    }
  }
});


const scrollToTopBtn = document.getElementById("scrollToTop");

// Слухаємо скрол сторінки
window.addEventListener("scroll", () => {
  if (window.scrollY > 300) { // Показуємо кнопку після прокручування 300px
    scrollToTopBtn.style.display = "flex";
  } else {
    scrollToTopBtn.style.display = "none";
  }
});

// Функція прокрутки до верху
scrollToTopBtn.addEventListener("click", () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Плавна прокрутка
  });
});

const isTelegramBrowser = () => {
  const ua = navigator.userAgent;
  return /Telegram/.test(ua);
};

const isInAppBrowser = () => {
  const ua = navigator.userAgent;
  return /Instagram|Viber/.test(ua);
};

if (isTelegramBrowser()) {
  window.addEventListener('resize', (e) => e.preventDefault());
  document.querySelector(".hero").classList.add('in-app-browser');
  document.querySelector(".hero").style.height = `${window.innerHeight + 48}px`;
}

if (isInAppBrowser()) {
  window.addEventListener('resize', (e) => e.preventDefault());
  document.querySelector(".hero").classList.add('in-app-browser');
  document.querySelector(".hero").style.height = `${window.innerHeight - 48}px`;
}


// Вибираємо форму
const form = document.querySelector('form');

// Обробник події для відправки форми
form.addEventListener('submit', async (e) => {
  e.preventDefault(); // Запобігаємо перезавантаженню сторінки

  // Збираємо дані форми
  const formData = new FormData(form);
  const action = form.getAttribute('action');
  const apiUrl = window.location.origin + "/" + action; // Беремо API з адресного рядка + action

  console.log(apiUrl);
  
  try {
    // Відправка даних через fetch
    const response = await fetch(apiUrl, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Помилка при відправці форми');
    }

    const result = await response.json();
    
    fbq('track', 'Lead');

    // Показуємо модалку для успіху
    showModal("Ваші дані успішно відправлені! Найближчим часом я зв'яжуся з вами ❤️");

    // Скидаємо форму після успішної відправки
    form.reset();
  } catch (error) {
    // Показуємо тостер для помилки
    showToast('Помилка при відправці форми. Спробуйте ще раз.', 'error');
    console.error('Помилка:', error);
  }
});

// Функція для показу модалки
function showModal(message) {
  const modal = document.createElement('div');
  modal.className = 'modal';

  modal.innerHTML = `
    <div class="modal__content">
      <p>${message}</p>
      <button class="modal__close">Чудово</button>
    </div>
  `;

  document.body.appendChild(modal);

  // Початкове значення opacity: 0
  setTimeout(() => {
    modal.classList.add('fade-in'); // Додаємо клас для fade-in після додавання модалки в DOM
  }, 50);

  document.body.style.overflow = 'hidden'; // Блокуємо прокрутку

  // Закриваємо модалку при кліку на кнопку
  const closeModal = () => {
    modal.classList.remove('fade-in');
    modal.classList.add('fade-out');
    setTimeout(() => {
      modal.remove();
      document.body.style.overflow = 'auto'; // Відновлюємо прокрутку
    }, 500); // Затримка для завершення анімації
  };

  modal.querySelector('.modal__close').addEventListener('click', closeModal);

  // Автоматичне закриття через 5 секунд
  setTimeout(() => {
    closeModal();
  }, 3000);
}


// Функція для показу тостерів
function showToast(message, type) {
  const toast = document.createElement('div');
  toast.className = `toast toast--${type}`;
  toast.textContent = message;

  // Додаємо тостер у body
  document.body.appendChild(toast);

  // Видаляємо тостер через 3 секунди
  setTimeout(() => {
    toast.remove();
  }, 3000);
}

// Додаємо стилі для тостерів та модалки
const style = document.createElement('style');
style.textContent = `
  .toast {
    position: fixed;
    bottom: 20px;
    right: 24px;
    left: 24px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    z-index: 10000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadein 0.5s, fadeout 0.5s 2.5s;

     @media only screen and (min-width: 600px) {
      left: auto;
    }
  }

  .toast--success {
    background-color: #4caf50; /* Зелений для успіху */
  }

  .toast--error {
    background-color: #f44336; /* Червоний для помилки */
  }

  @keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    transition: opacity 0.5s ease;
    padding: 24px;
  }

  .modal.fade-in {
    opacity: 1;
  }

  .modal.fade-out {
    opacity: 0;
  }

  .modal__content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }

  .modal__content p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .modal__close {
    padding: 10px 20px;
    background-color: #e38b8b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
  }

  .modal__close:hover {
    background-color: #cc7a7a;
  }
`;

document.head.appendChild(style);

document.querySelectorAll("[data-fbq]").forEach(el => el.addEventListener("click", () => {
  if (el.dataset.fbq === "Purchase") {
    fbq('track', 'Purchase');
  }
  else {
    fbq('trackCustom', el.dataset.fbq); 
  }
}))

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", () => {
  gsap.from(".hero", { opacity: 0, duration: 1 });

  const scrollElements = document.querySelectorAll("[data-gsap-scroll]");

  scrollElements.forEach(s => {
    gsap.from(s, {
      opacity: 0,
      y: 50,
      ease: "none",
      scrollTrigger: {
        trigger: s,
        start: "top 105%",
        end: "to 90%",
        scrub: 1,
        normalize: true
      }
    });
  });

  gsap.from(text, {
    scrollTrigger: {
      trigger: text,
      start: "top 90%",
      onEnter: typeWriter
    }
  });
});

const text = document.querySelector(".typing");
const content = text.textContent;
text.textContent = "";
let index = 0;

const typeWriter = () => {
  if (index < content.length) {
    text.textContent += content.charAt(index);
    index++;
    setTimeout(typeWriter, 100);
  }
};
