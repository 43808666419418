export default class Switches {
	#tabNodes;
	#contentNodes;
	#containerNode;
	#btnSelector = "data-tab-btn";
	#contentSelector = "data-tab-content";
	#callback;
	#ariaName;
	
	constructor(containerSelector, module, ariaName) {
		this.#containerNode = document.querySelector(containerSelector);
		this.#callback = module;
		this.#ariaName = ariaName;
		if(!this.#containerNode) return;

		this.#tabNodes = this.#containerNode.querySelectorAll(`[${this.#btnSelector}]`);
		this.#contentNodes = this.#containerNode.querySelectorAll(`[${this.#contentSelector}]`);

		this.#containerNode.addEventListener("click", this._changeTab.bind(this));
	}

	_changeTab(e) {
		const clicked = e.target.closest(`[${this.#btnSelector}]`);
    
		// Guard clause
		if (!clicked) return;
    
    const targetContent = this.#containerNode.querySelector(`[${this.#contentSelector}="${clicked.dataset.tabBtn}"]`);
	
		this.#callback(clicked, targetContent, this.#tabNodes, this.#contentNodes, this.#ariaName);
	}
}